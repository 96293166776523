<template>
    <BranchForm :branch="branch" v-if="loaded" :readOnly="false" />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import BranchForm from './BranchForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BranchForm
  },
  data() {
    return {
      loaded:false,
      branch:null
    }
  },
  methods:{
    
  },
  async mounted()
  {
    this.branch=await APIRequest.detail('branches',this.$route.params.id)
    this.loaded=true
  }
}
</script>
